import React, { useEffect, useState } from 'react';
import {
  Button, Grid, Divider, IconButton, Modal, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import './EvalHeader.css';
import * as R from 'ramda';
import { operations as evalOperations, selectors as evalSelectors } from 'ducks/eval';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { operations as tombstoneOperations, selectors as tombstoneSelectors } from 'ducks/tombstone';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import Loader from 'components/Loader';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationPopup from '../ConfirmationPopup';
import {
  INVALID_STATUS_FOR_REJECT, REQUIRED_USER_GROUPS, STANDARDEVAL_REQUIRED_USER_GROUPS,
  REPAYMENT_CASE_TYPE, NONDELAPPROVALDATE_CASETYPE, DISASTER_EDIT_USER_GROUPS,
  OPEN, NON_DELEGATED, NON_DEL_APPROVAL_DATE, LOCKED_DELEGATE_TYPE, NON_DEL_DATE_ERR_MSG1,
  NON_DEL_DATE_ERR_MSG2, NON_DEL_DATE_ERR_MSG3, EVAL_WIDGET,
  REJECTED, VP_USER_GROUP, UNREJECT_TABLE_COLUMNS,
  AVP_USER_GROUP, DISASTER, REJECTED_BUTTON_GROUPS,
} from '../../../constants/eval';
import { GRPS_CANREJECTCASE } from '../../../constants/appGroupName';
import OverrideMessage from '../OverrideMessage';
import RejectReasonPopup from '../RejectReasonPopup/RejectReasonPopup';
import { STANDARD_EVAL_SCREEN } from '../../../constants/loanInfoComponents';
import EvalPopup from '../EvalPopup/EvalPopup';
import SweetAlertBox from '../../SweetAlertBox';
import CaseRejectPopUpComp from '../CaseRejectPopUp/CaseRejectPopUp';
import EvalTable from '../EvalTable';

function EvalHeader(props) {
  const {
    headerData, setChecklistCenterPaneData, user, checklistCenterPaneView, calculateCaseRules,
    lockCaseRules, saveLockCase, toggleLockCase, lockValicationStatus, inProgress, caseInformation,
    universalActionResp, isEditable, generalInformation, fetchStates,
    isDisasterPopupOpen, toggleDisasterPopup, evalHeaderInProgress, isOpen, showSmduPopup,
    setOpenSmduPopup, evalScreenIcon, groupName, fetchRejectReasons,
    evalScreenInfo, setUnRejectBtn, resultData,
  } = props;
  const {
    evalCaseHeader, evalCaseType, createdOn, status, subStatus, evalHeader = {}, lockedFlag,
    createBy,
    data: {
      caseId,
    }, caseInfo,
  } = headerData;

  const [isDisabled, setDisabled] = useState(true);
  const [isDisasterEditIconDisabled, setDisasterEditIconDisabled] = useState(true);
  const [isRejectDisabled, setRejectDisabled] = useState(true);
  const [lockValidation, setLockValidation] = useState({
    isPopupVisible: false,
    popUpMessage: '',
  });
  const [openCaseRejPopup, setOpenCaseRejPopup] = useState(false);
  const [unRejectBtnInfo, setUnRejectBtnInfo] = useState({
    showPopup: false,
    enableUnRejectBtn: false,
    isEvalApproved: false,
    selectedSkills: [],
    isMultipleTrialOrModRecord: false,
  });
  const [caseInfoData, setCaseInfoData] = useState({});

  const { setRejectreasonPopupStatus } = props;

  const invalidStatusForReject = R.any(
    item => (item.caseType === REPAYMENT_CASE_TYPE && evalCaseType === DISASTER
      ? false : INVALID_STATUS_FOR_REJECT.includes(item.status)),
  )(caseInfo || []);

  useEffect(() => {
    const isStandardEval = STANDARD_EVAL_SCREEN.includes(checklistCenterPaneView);
    const userGroups = user && user.groupList;
    if (REQUIRED_USER_GROUPS.some(group => userGroups.includes(group))) {
      setDisabled(false);
    } else if (isStandardEval && evalCaseType === REPAYMENT_CASE_TYPE
      && STANDARDEVAL_REQUIRED_USER_GROUPS.some(group => userGroups.includes(group))) {
      setDisabled(false);
    }
    if (
      (userGroups.includes(VP_USER_GROUP) || userGroups.includes(AVP_USER_GROUP))
      && REJECTED_BUTTON_GROUPS.some(group => userGroups.includes(group))
    ) {
      const disableReject = (status === 'Rejected' || invalidStatusForReject) || !isEditable;
      setRejectDisabled(disableReject);
    } else {
      setRejectDisabled(true);
    }
    if (DISASTER_EDIT_USER_GROUPS.some(group => userGroups.includes(group))) {
      setDisasterEditIconDisabled(false);
    }
    if (userGroups.includes(VP_USER_GROUP) && userGroups.includes(AVP_USER_GROUP)) {
      setUnRejectBtnInfo(prev => ({ ...prev, enableUnRejectBtn: true }));
    }
  }, [user, checklistCenterPaneView]);

  useEffect(() => {
    if (!R.isEmpty(evalScreenInfo)
      && !R.isNil(evalScreenInfo.evalCase)
      && !R.isNil(evalScreenInfo.caseInfo)) {
      const { evalCase: { approved } } = evalScreenInfo;
      setCaseInfoData(!R.isNil(evalScreenInfo.caseInfo) && evalScreenInfo.caseInfo.filter(
        arr => arr.status === REJECTED && arr.sentForApproval === 1,
      ));
      setUnRejectBtnInfo(prev => ({ ...prev, isEvalApproved: approved }));
    }
  }, [evalScreenInfo]);

  const findColIndex = caseInformation.findIndex(i => (i.columnName === 'workoutType'));
  const { resolutionChoiceType } = universalActionResp;
  const enableNonDelApprovalDate = (
    NONDELAPPROVALDATE_CASETYPE.includes(resolutionChoiceType)
    || (resolutionChoiceType === 'Traditional Modification' && caseInformation[findColIndex].columnVal === 'VA Traditional Modification')
  );

  const checkNonDelegationDate = () => {
    let valid = true;
    let nonDelDateInfo = null;
    let delegateType = '';
    const delegateTypeInfo = generalInformation.find(i => (i.columnName === LOCKED_DELEGATE_TYPE));
    if (delegateTypeInfo) {
      delegateType = delegateTypeInfo.columnVal ? delegateTypeInfo.columnVal : '';
    }
    const nonDelDateFromGenInfo = generalInformation
      .find(i => (i.columnName === NON_DEL_APPROVAL_DATE));
    const nonDelDateFromCaseInfo = caseInformation
      .find(i => (i.columnName === NON_DEL_APPROVAL_DATE));
    if (nonDelDateFromGenInfo) nonDelDateInfo = nonDelDateFromGenInfo;
    if (nonDelDateFromCaseInfo) nonDelDateInfo = nonDelDateFromCaseInfo;
    const nonDelegatedDate = nonDelDateInfo.columnVal ? nonDelDateInfo.columnVal.toString() : '';
    const currentDate = moment().toString();
    if (delegateType === NON_DELEGATED && status === OPEN) {
      if (nonDelegatedDate === '') {
        setLockValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG1,
        }));
        valid = false;
      }
      if (moment(nonDelegatedDate).isBefore('2020-01-01', 'year')) {
        setLockValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG2,
        }));
        valid = false;
      }
      if (moment(nonDelegatedDate).isAfter(currentDate)) {
        setLockValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG3,
        }));
        valid = false;
      }
    }
    return valid;
  };

  const handleLock = () => {
    if (!enableNonDelApprovalDate) {
      lockCaseRules();
    } else {
      const validNonDelAppDate = checkNonDelegationDate();
      if (validNonDelAppDate) {
        lockCaseRules();
      }
    }
  };

  const handleCalculate = () => {
    if (!enableNonDelApprovalDate) {
      calculateCaseRules();
    } else {
      const validNonDelAppDate = checkNonDelegationDate();
      if (validNonDelAppDate) {
        calculateCaseRules();
      }
    }
  };
  const handleBack = () => {
    setChecklistCenterPaneData(STANDARD_EVAL_SCREEN);
  };

  const handleReject = () => {
    setRejectreasonPopupStatus(true);
  };

  const handleCaseReject = () => {
    fetchRejectReasons();
    setOpenCaseRejPopup(true);
  };

  const handleCloseCaseRejPopUp = () => {
    setOpenCaseRejPopup(false);
  };

  const handleLockPopupClose = () => {
    setLockValidation(prev => ({ ...prev, isPopupVisible: false }));
  };

  const handleClose = () => {
    toggleLockCase(false);
  };

  const handleConfirm = () => {
    saveLockCase();
  };

  const handleSmduPopupClose = () => {
    setOpenSmduPopup(false);
  };

  const handleSmduPopupConfirm = () => {
    setOpenSmduPopup(false);
    toggleLockCase(true);
  };

  const displayRejectBtn = () => (!!(status && status.toLowerCase() !== 'rejected'
  && evalScreenIcon === EVAL_WIDGET
   && R.contains(groupName, GRPS_CANREJECTCASE)));

  const handleUnReject = () => {
    const { selectedSkills } = unRejectBtnInfo;
    const validateWorkoutTypes = selectedSkills && selectedSkills.map(c => caseInfoData.find(i => i.caseId === c)).filter(x => x.workoutType === 'M' || x.workoutType === 'T');
    const uniqueValues = new Set(validateWorkoutTypes.map(v => v.workoutType));
    if (uniqueValues.size === validateWorkoutTypes.length) {
      setUnRejectBtn({ evalId: evalHeader.evalId, caseId: selectedSkills });
      setUnRejectBtnInfo(prev => ({
        ...prev, showPopup: false, selectedSkills: [],
      }));
    } else {
      setUnRejectBtnInfo(prev => ({
        ...prev,
        isMultipleTrialOrModRecord: true,
        selectedSkills: [],
      }));
    }
  };

  const handleSelectedRows = (list) => {
    setUnRejectBtnInfo(prev => (
      {
        ...prev,
        selectedSkills: list,
      }));
  };
  return (
    <>
      {evalHeaderInProgress && (
        <div styleName="evalHeaderLoader">
          <Loader message="Please wait" size={10} />
        </div>
      )}
      <Grid container styleName="eval-header-container">
        <Grid item styleName="evalcase-info-container">
          {evalCaseHeader ? (
            <Grid styleName="evalcase-header-content">
              <div>
                <Grid item styleName="case-id" xs={12}>
                  <h1>{!R.isNil(evalCaseType) && (`${evalCaseType}`)}</h1>
                </Grid>
                <Grid item styleName="case-id">
                  <div styleName="case-id2">
                    <span>{`${evalHeader && evalHeader.evalId}`}</span>
                    {!R.isNil(evalHeader && evalHeader.disasterFlag) && (
                    <>
                      <IconButton
                        aria-label="Edit"
                        component="span"
                        disabled={evalCaseType !== 'Pandemic' ? isDisasterEditIconDisabled : true}
                        onClick={() => {
                          fetchStates();
                          toggleDisasterPopup(true);
                        }}
                        styleName="btn-edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <span styleName="disaster-wrapper">
                        {!R.isNil(evalCaseType) && (
                        <>
                          {evalCaseType !== 'Pandemic' && R.isNil(evalHeader.disasterId)
                      && (
                      <>
                        <span styleName="text-red">Pending</span>
                        <span>Disaster Mapping</span>
                      </>
                      )}
                          <span styleName="text-red">{evalCaseType === 'Pandemic' ? 'COVID-19' : evalHeader.disasterName}</span>
                          {(evalCaseType === 'Pandemic' || evalHeader.disasterId !== null)
                            ? (
                              <span styleName="disaster-info">
                                {`declared on ${evalCaseType === 'Pandemic' ? '13 Mar 2020' : evalHeader.disasterDate} `}
                              </span>
                            ) : null}
                        </>
                        )}
                        <div styleName="escrowBanner">
                          {!R.isNil(evalHeader.escrowedDisasterFlag) && R.equals(evalHeader.escrowedDisasterFlag, '1') && (
                          <>
                            {`| Escrowed at the time of ${evalCaseType === 'Pandemic' ? 'COVID-19' : evalHeader.disasterName || ''} disaster `}
                          </>
                          )}
                          {!R.isNil(evalHeader.disasterFlag)
                    && (R.equals(evalHeader.disasterFlag.trim(), 'E') || R.equals(evalHeader.disasterFlag.trim(), 'PE')) && (
                      <>
                        {'| Employer Effected'}
                      </>
                          )}
                        </div>
                      </span>
                    </>
                    )}
                  </div>
                </Grid>
                <EvalPopup
                  evalDtls={{
                    disasterFlag: evalHeader && evalHeader.disasterFlag ? evalHeader.disasterFlag.trim() : '',
                    disasterId: evalHeader && evalHeader.disasterId,
                    state: evalHeader && evalHeader.state,
                    county: evalHeader && evalHeader.county,
                  }}
                  handleClose={() => toggleDisasterPopup(false)}
                  showPopup={isDisasterPopupOpen}
                  title="Disaster Search"
                />
              </div>
            </Grid>
          ) : (
            <>
              <h1>{evalCaseType}</h1>
              <div styleName="case-id3">
                <span>{caseId}</span>
              </div>
            </>
          )}

        </Grid>
        <Grid item>
          <div styleName="top-head">
            <p>
              {'Creator: '}
              <span>{createBy}</span>
            </p>
            <p>
              {'Created on '}
              <span>{createdOn}</span>
            </p>
            <p>
              {'Status: '}
              <span>{status}</span>
            </p>
            <p>
              {'Sub-Status: '}
              <span>{subStatus}</span>
            </p>
          </div>
          {!evalCaseHeader && (
            <div styleName="bottom-head">
              <div>
                {displayRejectBtn() && (
                <Button color="primary" onClick={handleCaseReject} style={{ borderWidth: '2px' }} variant="outlined">
                 REJECT
                </Button>
                )}
                {status && status.toLowerCase() === 'open' && (
                  <>
                    <Button color="primary" disabled={isDisabled || inProgress || lockedFlag === 1 || evalHeaderInProgress} onClick={handleCalculate} style={{ borderWidth: '2px' }} variant="outlined">
                  CALCULATE
                    </Button>
                    <Button color="primary" disabled={isDisabled || inProgress || lockedFlag === 1 || evalHeaderInProgress} onClick={handleLock} variant="contained">
                  LOCK
                    </Button>
                  </>
                )}
                <SweetAlertBox
                  message={lockValidation.popUpMessage}
                  onConfirm={handleLockPopupClose}
                  show={lockValidation.isPopupVisible}
                  type="Failed"
                />
                <ConfirmationPopup
                  cancelButtonText="NO"
                  confirmButtonText="YES"
                  message="Are you sure you want to lock?"
                  onCancel={handleClose}
                  onConfirm={handleConfirm}
                  show={resultData ? (!resultData.isOpen && lockValicationStatus)
                    : lockValicationStatus}
                  showCancelButton
                  showConfirmButton
                />
                <ConfirmationPopup
                  cancelButtonText="NO"
                  confirmButtonText="YES"
                  message="No eligible SMDU workouts found. Are you sure you want to lock this case and bypass SMDU? This operation cannot be undone."
                  onCancel={handleSmduPopupClose}
                  onConfirm={handleSmduPopupConfirm}
                  show={showSmduPopup}
                  showCancelButton
                  showConfirmButton
                />
              </div>
              <Button
                onClick={handleBack}
                size="large"
                startIcon={<ArrowBackIosIcon />}
                styleName="back-button"
              >
                BACK
              </Button>
            </div>
          )}
          {
            evalCaseHeader && (
              <div styleName="bottom-head">
                {evalHeader && evalHeader.milestoneMessage && (
                  <p styleName="small-text">
                    <span>{evalHeader.milestoneMessage || ''}</span>
                  </p>
                )}

                {unRejectBtnInfo.enableUnRejectBtn && status === 'Rejected' ? (
                  <Button color="primary" onClick={() => setUnRejectBtnInfo(prev => ({ ...prev, showPopup: true }))} styleName="rejectButton" variant="outlined">
                    UNREJECT
                  </Button>
                )
                  : (
                    <Button color="primary" disabled={isRejectDisabled} onClick={handleReject} styleName="rejectButton" variant="outlined">
                    REJECT
                    </Button>
                  )}
                <Modal
                  BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.8)' } }}
                  disableBackdropClick
                  onClose={() => () => setUnRejectBtnInfo(prev => ({ ...prev, showPopup: false }))}
                  open={unRejectBtnInfo.showPopup}
                >
                  <div styleName="unreject-popup-container">
                    {unRejectBtnInfo.isEvalApproved ? (
                      <div>
                        <div styleName="close-btn-header">
                          <Typography variant="h2">
                          UnReject Case Details
                          </Typography>
                          <IconButton
                            aria-label="close"
                            onClick={() => setUnRejectBtnInfo(prev => ({
                              ...prev, showPopup: false,
                            }))}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                        {!unRejectBtnInfo.isMultipleTrialOrModRecord ? (
                          <>
                            <EvalTable
                              columns={UNREJECT_TABLE_COLUMNS}
                              data={caseInfoData || []}
                              handleSelectedRows={handleSelectedRows}
                              selectRowCheckbox
                            />
                            <div styleName="button-unreject-container">
                              <Button
                                color="primary"
                                disabled={unRejectBtnInfo.selectedSkills.length <= 0}
                                disableElevation
                                onClick={handleUnReject}
                                variant="contained"
                              >
                                OK
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            <Typography style={{ textAlign: 'center' }} variant="h2">
                              {'Please select only one trial case and/or one mod case to be unrejected/unfailed'}
                            </Typography>
                            <div styleName="button-unreject-container">
                              <Button color="primary" disableElevation onClick={() => setUnRejectBtnInfo(prev => ({ ...prev, isMultipleTrialOrModRecord: false }))} variant="contained">
                                  OK
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <Typography style={{ textAlign: 'center' }} variant="h2">
                          {'Can\'t Unreject - Eval case has not been approved previously. contact your manager'}
                        </Typography>
                        <div styleName="button-unreject-container">
                          <Button color="primary" disableElevation onClick={() => setUnRejectBtnInfo(prev => ({ ...prev, showPopup: false }))} variant="contained">
                            OK
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
            )
          }
          {
            isOpen && <RejectReasonPopup />
          }
          {openCaseRejPopup
            && (
            <CaseRejectPopUpComp
              caseId={caseId}
              handleClose={handleCloseCaseRejPopUp}
              isOpen={openCaseRejPopup}
            />
            )}
        </Grid>
        {!evalCaseHeader && <OverrideMessage />}
      </Grid>
      {!evalCaseHeader && (<Divider style={{ marginBottom: '8px' }} />)}
    </>
  );
}

EvalHeader.defaultProps = {
  setRejectreasonPopupStatus: () => { },
  caseInformation: [],
  checklistCenterPaneView: 'Checklist',
  generalInformation: [],
  inProgress: false,
  isEditable: true,
  lockValicationStatus: false,
  headerData: { evalHeader: {} },
  universalActionResp: {},
  evalHeaderInProgress: false,
  evalScreenIcon: '',
};

EvalHeader.propTypes = {
  calculateCaseRules: PropTypes.func.isRequired,
  caseInformation: PropTypes.arrayOf(PropTypes.shape()),
  checklistCenterPaneView: PropTypes.string,
  evalHeaderInProgress: PropTypes.bool,
  evalScreenIcon: PropTypes.string,
  evalScreenInfo: PropTypes.shape().isRequired,
  fetchRejectReasons: PropTypes.func.isRequired,
  fetchStates: PropTypes.func.isRequired,
  generalInformation: PropTypes.arrayOf(PropTypes.shape()),
  groupName: PropTypes.string.isRequired,
  headerData: PropTypes.shape({
    caseInfo: PropTypes.shape({}),
    createBy: PropTypes.string,
    createdOn: PropTypes.string,
    data: PropTypes.shape({
      caseId: PropTypes.number,
    }),
    evalCaseHeader: PropTypes.bool,
    evalCaseType: PropTypes.string,
    evalHeader: PropTypes.any,
    lockedFlag: PropTypes.number,
    status: PropTypes.string,
    subStatus: PropTypes.string,
  }),
  inProgress: PropTypes.bool,
  isDisasterPopupOpen: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  lockCaseRules: PropTypes.func.isRequired,
  lockValicationStatus: PropTypes.bool,
  resultData: PropTypes.shape({
    clearData: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    showConfirmButton: PropTypes.bool,
    status: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  saveLockCase: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setOpenSmduPopup: PropTypes.bool.isRequired,
  setRejectreasonPopupStatus: PropTypes.func,
  setUnRejectBtn: PropTypes.bool.isRequired,
  showSmduPopup: PropTypes.bool.isRequired,
  toggleDisasterPopup: PropTypes.func.isRequired,
  toggleLockCase: PropTypes.func.isRequired,
  universalActionResp: PropTypes.shape(),
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  caseInformation: evalSelectors.caseInformation(state),
  generalInformation: evalSelectors.generalInformation(state),
  user: loginSelectors.getUser(state),
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
  inProgress: evalSelectors.isCalculateLoading(state),
  universalActionResp: evalSelectors.universalActionResp(state),
  lockValicationStatus: evalSelectors.lockValicationStatus(state),
  isDisasterPopupOpen: evalSelectors.isDisasterPopupOpen(state),
  isOpen: evalSelectors.popUpStatus(state),
  showSmduPopup: evalSelectors.showSmduPopup(state),
  evalScreenIcon: evalSelectors.getEvalScreenIcon(state),
  groupName: dashboardSelectors.groupName(state),
  evalScreenInfo: evalSelectors.getEvalScreenData(state),
  resultData: dashboardSelectors.resultOperation(state),
});
const mapDispatchToProps = dispatch => ({
  setRejectreasonPopupStatus: evalOperations.setRejectreasonPopupStatusOperation(dispatch),
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  calculateCaseRules: evalOperations.calculateCaseRulesOpn(dispatch),
  lockCaseRules: evalOperations.lockCaseRulesOperation(dispatch),
  saveLockCase: evalOperations.saveLockCaseOperation(dispatch),
  toggleLockCase: evalOperations.toggleLockCaseOperation(dispatch),
  fetchStates: evalOperations.fetchStatesOperation(dispatch),
  toggleDisasterPopup: evalOperations.toggleDisasterPopupOperation(dispatch),
  setOpenSmduPopup: evalOperations.setSmduPopupOperation(dispatch),
  fetchRejectReasons: evalOperations.fetchCaseRejReasonsOpn(dispatch),
  setUnRejectBtn: evalOperations.setUnRejectStatusOperation(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EvalHeader);
